import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ProjectIntroDetails from '../components/ProjectIntroDetails';
import ModularBlocks from '../components/ModularBlocks';
import FeaturedProjects from '../components/FeaturedProjects';

const ProjectDetailsTemplate = ({
  data: {
    datoCmsProject: {
      seoMetaTags,
      title,
      categories,
      heading,
      text,
      location,
      completionDate,
      servicesProvided,
      projectDuration,
      modularBlocks,
      relatedProjects,
    },
  },
}) => {
  const bannerText = categories.map(({ name }) => name).join(', ');

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} />
        <ProjectIntroDetails
          heading={heading}
          text={text}
          details={{
            location,
            completionDate,
            servicesProvided,
            projectDuration,
          }}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
      <FeaturedProjects
        heading="Other Recent Projects"
        projects={relatedProjects}
      />
    </Layout>
  );
};

export const ProjectDetailsTemplateQuery = graphql`
  query ProjectDetailsTemplateQuery($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      categories {
        name
      }
      heading
      text
      location
      completionDate
      servicesProvided
      projectDuration
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageSliderModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...TestimonialModularBlockFragment
      }
      relatedProjects {
        heading
        slug
        categories {
          name
          slug
        }
        featuredImage {
          gatsbyImageData(width: 950, height: 660)
        }
      }
    }
  }
`;

export default ProjectDetailsTemplate;
