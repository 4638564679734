import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  standardColours,
  fontSize,
} from '../styles';
import { Container, HtmlContent } from './ui';

const StyledProjectIntroDetails = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 100px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 140px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 180px;
  `}
`;

const StyledContent = styled.div``;

const StyledHeading = styled.h2`
  color: ${brandColours.primary};
  ${fontSize(24)};

  ${minBreakpointQuery.small`
    ${fontSize(27)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledDetails = styled.ul``;

const StyledDetail = styled.li`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid ${standardColours.black};

  span {
    font-weight: ${fontWeights.semibold};
  }
`;

const ProjectIntroDetails = ({
  heading,
  text,
  details: { location, completionDate, servicesProvided, projectDuration },
}) => (
  <StyledProjectIntroDetails>
    <Container>
      <StyledInner>
        <StyledContent>
          <StyledHeading>{heading}</StyledHeading>
          <StyledText html={text} />
        </StyledContent>
        <StyledDetails>
          <StyledDetail>
            <span>Location</span> {location}
          </StyledDetail>
          <StyledDetail>
            <span>Completion Date</span> {completionDate}
          </StyledDetail>
          <StyledDetail>
            <span>Services Provided</span> {servicesProvided}
          </StyledDetail>
          <StyledDetail>
            <span>Project Duration</span> {projectDuration}
          </StyledDetail>
        </StyledDetails>
      </StyledInner>
    </Container>
  </StyledProjectIntroDetails>
);

export default ProjectIntroDetails;
